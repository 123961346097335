import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import '../styles/_bootstrap.scss'
import './index.scss';

class Jobs extends React.PureComponent<{},{}> {
    public render() {
        return (
            <>
                <Header />
                <div className="page">
                    <div className="container-fluid hero">
                        <section className="hero-with-image hero-big jobs" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://assets.direktek.co.uk/images/port.jpeg')" }}>
                            <div className="container">
                                <h1 className="padding-bottom-M">We&#8217;re Hiring</h1>
                                <p className="text-XL padding-bottom-M">Come shape the future of supply chain</p>
                                <div className="btn btn-white">Learn more</div>
                            </div>
                        </section>

                        <section className="section border-bottom padding-bottom-XL">
                            <div className="container padding-none jobs intro">
                                <div className="col-sm-8 col-sm-offset-2 col-xs-12">
                                    <h2 className="padding-bottom-M">Join us in empowering global brands and retailers</h2>
                                    <p className="text-L">Our mission is to build vertical supply-chain infrastructure to enable exceptional retail experiences. We pride ourselves on unrivalled flexibility for our customers, being technology-led and our drop-shipping expertise.</p>
                                </div>
                                <div className="col-xs-12 margin-top-M">
                                    <div className="features-container">
                                        <div className="col-xs-12 col-sm-4">
                                            <div className="feature-panel">
                                                <h3>Challenges</h3>
                                                <p>We want to be a truly innovative company and this goes hand-in-hand with making it an inspiring place to work. We&#8217;re looking for people who can think big, solve operational challenges and help us scale together.</p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-4">
                                            <div className="feature-panel">
                                                <h3>Ownership</h3>
                                                <p>We are built on a culture of ownership and trust with little hierarchy. We want to surround ourselves with enthusiastic, self-starters who aren't looking to be micro-managed and can challenge the status quo.</p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-4">
                                            <div className="feature-panel">
                                                <h3>Not Just Another Number</h3>
                                                <p>We're proud to be a medium-sized business, that is growing with exciting prospects for the future. However we are still small enough for you to make a huge mark on the company and shape your role over time.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="container-fluid padding-none body">
                        <section className="section jobs">
                            <div className="container padding-none">
                                <div className="col-sm-8 col-sm-offset-2 col-xs-12">
                                    <div className="listings text-left">
                                        <div className="indeedjobs-widget" data-id="3d8422cf086fc5326987" data-theme="light" data-height="320"></div> 
                                        <p className="text-M position-lede">We&#8217;re always on the lookout for brilliant people to join us. If you don't see a position for you but think you&#8217;d be a great fit, feel free to <a className="typeform-share" data-mode="1" href="https://form.jotformeu.com/61722503068350" target="_blank">send us a message</a>!</p>
                                        <h3 className="positions-header margin-top-L text-XL text-red">Internships &amp; Placements</h3><br />
                                        <p className="position-lede">We also offer paid internships/placements in our technology, sales and marketing teams - please apply by emailing <a href="mailto:recruitment@direktek.co.uk">recruitment@direktek.co.uk</a> with the area of the business you are interested in joining, your CV and include "Internship" in the subject.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="background-grey border-bottom" id="apply">
                            <div className="container section jobs text-left">
                                <div className="cta-box-light">
                                    <div className="col-md-7 col-sm-12 text-left">
                                        <h2 className="text-red">Register to hear about future openings</h2>
                                    </div>
                                    <div className="col-md-5 col-sm-12 margin-top-M">
                                        <a className="btn btn-success text-L text-center squared margin-bottom-M" data-mode="1" href="https://form.jotformeu.com/61722503068350" target="_blank">Register Now</a>
                                        <small><p className="text-secondary">We do not accept unsolicited resumes from recruiters or staffing agencies.</p></small>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

   componentDidMount() {
       console.log('ran!')
       const element = document.querySelector('.indeedjobs-widget');
       const script = document.createElement('script');
       if(element != null) {
           script.id = 'indeedjobs-js';
           script.src = 'https://www.indeedjobs.com/widget.js'; 
           console.log(script);
           document.head.appendChild(script); 
       }
   }
}

export default Jobs;